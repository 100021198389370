import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Image from 'common/src/components/Image';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import FeatureSectionTwoWrapper from './featureSliderTwo.style';
 
import Thumb1 from 'common/src/assets/image/app/1-herramientas.svg';
import Thumb2 from 'common/src/assets/image/app/2-cursos.svg';
import Thumb3 from 'common/src/assets/image/app/3-aceptacion.svg';
import Thumb4 from 'common/src/assets/image/app/4-equilibrio.svg';
import Thumb5 from 'common/src/assets/image/app/5-miedos.svg';
import Thumb6 from 'common/src/assets/image/app/6-meditaciones.svg';
import Thumb7 from 'common/src/assets/image/app/7-creencias.svg';
import Thumb8 from 'common/src/assets/image/app/8-videos.svg';

// import DomainSection from '../container/Hosting/Domain';
const features = [
  {   
    thumbnail: `${Thumb1}`,
    thumbnailLabel: 'Herramientas para Sanar',     
    thumbnailAlt: 'herramientas',
  },
  {     
    thumbnail: `${Thumb2}`,
    thumbnailLabel: 'Cursos de Gestión Emocional',     
    thumbnailAlt: 'cursos',
  },
  {     
    thumbnail: `${Thumb3}`,
    thumbnailLabel: 'Aceptación y Gestión de Crisis',    
    thumbnailAlt: 'aceptación',
  },
  {     
    thumbnail: `${Thumb4}`,
    thumbnailLabel: 'Recupera tu Equilibrio',     
    thumbnailAlt: 'equilibrio',
  },
  {     
    thumbnail: `${Thumb5}`,
    thumbnailLabel: 'Aprende a Superar tus miedos',    
    thumbnailAlt: 'miedos',
  },
  {     
    thumbnail: `${Thumb6}`,
    thumbnailLabel: 'Meditaciones para tu Paz interior', 
    thumbnailAlt: 'meditaciones',
  },
  {     
    thumbnail: `${Thumb7}`,
    thumbnailLabel: 'Inducciones para cambios de Creencias',    
    thumbnailAlt: 'inducciones',
  },
  {   
    thumbnail: `${Thumb8}`,
    thumbnailLabel: 'Videos, Audios y Ejercicios para tu Desarrollo',    
    thumbnailAlt: 'audios',
  },
];

const FeatureSection = ({
  row,
  col,
  sectionHeader,
  sectionTitle,
  sectionSubTitle,
  featureTitle,
  iconStyle,
  contentStyle,
  blockWrapperStyle,
}) => {
  //const Data = 
  useStaticQuery(graphql`
    query {
      appJson {
        featuresSliderTwo {
          id
          title
          image {
            publicURL
          }
        }
      }
    }
  `);

  return (
    <FeatureSectionTwoWrapper id="keyfeatures">
      <Container>
        <Box {...sectionHeader}>
          <Text {...sectionSubTitle} />
          <Heading {...sectionTitle} />
        </Box>
        <Box className="row" {...row}>
          {features.map((feature, index) => (
            <Box className="col" {...col} key={index}>
              <Fade bottom delay={index * 120}>
                <FeatureBlock
                  icon={
                    <Image src={feature.thumbnail} alt={feature.thumbnailAlt} />
                  }
                  wrapperStyle={blockWrapperStyle}
                  iconStyle={iconStyle}
                  contentStyle={contentStyle}
                  title={<Heading content={feature.thumbnailLabel} {...featureTitle} />}
                />
              </Fade>
            </Box>
          ))}
        </Box>
      </Container>
    </FeatureSectionTwoWrapper>
  );
};

// FeatureSection style props
FeatureSection.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  featureTitle: PropTypes.object,
  featureDescription: PropTypes.object,
};

// FeatureSection default style
FeatureSection.defaultProps = {
  // section header default style
  sectionHeader: {
    mb: ['56px', '56px'],
  },
  // sub section default style
  sectionSubTitle: {
    content: 'QUE ENCONTRARÁS EN LUZIA',
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.13em',
    fontWeight: '700',
    color: '#B200FF',
    mb: '10px',
  },
  // section title default style
  sectionTitle: {
    content: 'Gran cantidad de Contenido para tu Prosperidad',
    textAlign: 'center',
    fontSize: ['20px', '24px', '24px', '24px', '30px'],
    fontWeight: '400',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  // feature row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
  },
  // feature col default style
  col: {
    width: [1 / 2, 1 / 2, 1 / 3, 1 / 3, 1 / 3],
  },
  // feature block wrapper default style
  blockWrapperStyle: {
    p: ['10px', '20px', '20px', '40px'],
  },
  // feature icon default style
  iconStyle: {
    width: '75px',
    height: '75px',
    m: '0 auto',
    borderRadius: '50%',
    bg: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '32px',
    color: '#29cf8a',
    overflow: 'hidden',
    mb: '15px',
  },
  // feature content default style
  contentStyle: {
    textAlign: 'center',
  },
  // feature title default style
  featureTitle: {
    fontSize: ['16px', '18px'],
    fontWeight: '400',
    color: '#0f2137',
    lineHeight: '1.5',
    mb: '20px',
    letterSpacing: '-0.020em',
  },
  // feature description default style
  featureDescription: {
    fontSize: ['14px', '15px'],
    lineHeight: '1.84',
    color: '#343d48cc',
  },
};

export default FeatureSection;
