import styled from 'styled-components';

// Chatbot container  style
const ChatbotContainer = styled.div`
  display:  block;
	z-index: 9999;
  position: fixed;
  bottom: 2vh;
  right: 2vw;
  pointer-events: none;
	left: auto;
  font-family: roboto, sans-serif;   
`; 
const Chatbot = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  pointer-events: none; 
`;

const CtaBubble  = styled.div`   
  padding: 1em 2em;
  border-radius: 2em;
  border-bottom-right-radius: 2em;
  border-bottom-right-radius: 0;
  height: -webkit-min-content;
  height: -moz-min-content;
  height: min-content;
  pointer-events: auto;
  font-size: 14px;
  border: 1px solid #eaeaea;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.3);
  background: white;
  color: #333;
  &.fade-in-right {
    -webkit-animation: fade-in-right 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) 4s both;
    animation: fade-in-right 0.6s cubic-bezier(0.39, 0.575, 0.565, 1) 4s both;
  }
`;

const LuziaAvatar = styled.img`
  border-radius: 50%;
  height: 6em;
  width: 6em;
  background-color: #ffffff;
  pointer-events: auto;
  margin-left: 1vw;
  min-width: 100px;
  min-height: 100px;
  &.blink {
    -webkit-animation: heartbeat 1.2s ease-in-out 3.3s both;
    animation: heartbeat 1.2s ease-in-out 3.3s both;
  }
`;


const PopupOverlay= styled.div`
  display: block;
  opacity: 1;
  z-index: 2;
  background-color: rgba( 0,0,0,0.60 );
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: initial;
  transition: .15s ease-in-out;
  position: fixed;
`;

const PopupContainer= styled.div`
  width: 380px;
  height: auto;
  bottom: 0%;
  right:  0.8%;
  display: block;
  opacity: 1;
  position: fixed;
  padding: 1px;
  border-radius: 1px;
  border: 3px solid #fff;
  box-shadow: 6px 0 30px 0 rgba( 2,2,2,1.00 );
  background-color: rgba( 255,255,255,1.00 );
  margin-bottom: 1em;  
  z-index: 3;
  box-sizing: border-box;
  /* transition: all 2s 2s;
    transform: translate(-102%, -25%); */ 
`;

const PopupContentContainer= styled.div`
  position: relative;
  z-index: 0;
`;

const PopupContent= styled.div`  
   
  &:last-child {
    margin-bottom: 0;
  }
  
`;

const CloseButton = styled.button`
  position: absolute;
  height: 26px;
  width: 26px;
  left: 1px;
  right: auto;
  bottom: auto;
  top: -2px;
  padding: 0;
  color: #fff;
  font-family: Comic Sans MS;
  font-weight: 600;
  font-size: 44px;
  line-height: 24px;
  border: 2px #fff;
  border-radius: 26px;
  text-decoration: none;
  text-align: center;
  line-height: 1;
  cursor: pointer;
  min-width: 1em;
  z-index: 2;
  background:transparent;
`;

ChatbotContainer.displayName = 'LuziaButton';

export {
  // bot button
  ChatbotContainer,
  Chatbot,
  CtaBubble,
  LuziaAvatar,

  // popup window
  PopupOverlay,
  PopupContainer,
  PopupContentContainer,
  PopupContent,
  CloseButton
};

export default ChatbotContainer;
