import React  from 'react';
import PropTypes from 'prop-types';
import {ChatbotContainer, Chatbot, CtaBubble, LuziaAvatar,
        PopupContainer, PopupContentContainer, PopupContent, CloseButton} 
        from './luziabutton.style';
import ReactModal from 'react-modal'

const LuziaButton = ({
  module,
  icon,
  ...props
}) => {
  // Add all classs to an array
  const addAllClasses = [];
  // set modal app element
  ReactModal.setAppElement('#___gatsby');
  // isLoading prop checking
  // if (isLoading) {
  //   addAllClasses.push('is-loading');
  // }
  //let ctaVisible = true;
  const [ctaIsOpen,setCtaOpen] = React.useState(true);
  const [modalIsOpen,setIsOpen] = React.useState(false);
  const [avatarVisible,setAvatarVisible] = React.useState(true);
  
  //setCtaOpen(true);

  const chatbotClick = () => {
    setCtaOpen(false);
    setAvatarVisible(false)
    // Open modal window
    console.log('Chatbot clicked, opening modal window')
    setIsOpen(true);
  };

  const handleModalClose = () => {
    setIsOpen(false);
    setAvatarVisible(true)
    console.log('Chatbot closing')
  }
   
  return (
    <ChatbotContainer       
      className={addAllClasses.join(' ')}      
      {...props}
    >
      <Chatbot 
        onClick={chatbotClick}
      >
        {ctaIsOpen? 
          <CtaBubble
            className="fade-in-right"
          >
            ¡Hola! ¿Cómo puedo ayudarte?  
          </CtaBubble> : ''}
        {avatarVisible? 
        <LuziaAvatar 
          className="blink"
          src={icon}
        >
        </LuziaAvatar> : '' }
      </Chatbot>
      <ReactModal
          isOpen={modalIsOpen}
          onRequestClose={handleModalClose}
          contentLabel="Luzia Chat"
          style={{
            overlay: {
              width: 0
            },
            content: {
              width: 0,
              padding: 0,
              border: 0
            }
          }}
        >  
          {/* <PopupOverlay> */}
          <PopupContainer>
          <PopupContentContainer>
          <PopupContent>
           <iframe 
              src={"https://app.luzia.chat/#/embed/"+module} 
              width="375" 
              height="650"                
              frameborder="0" 
              allow="autoplay; fullscreen" 
              title="Habla con Luzia"
              allowfullscreen>
           </iframe>
           </PopupContent>
           <CloseButton onClick={handleModalClose}>-</CloseButton>
           </PopupContentContainer>
           </PopupContainer>
           {/* </PopupOverlay>            */}
        </ReactModal>
    </ChatbotContainer>
  );
};

LuziaButton.propTypes = {
  /** module to call */
  module: PropTypes.string,

  /** Add icon */
  icon: PropTypes.string,
   
  /**
   * Gets called when the user clicks on the button
   */
  onClick: PropTypes.func,
};

LuziaButton.defaultProps = {
  icon:"https://luzia.chat/static/9c72e36cff1efa38d5067bb7161d4023/4dc99/luziaEmoji.jpg",
  module:"4gsjr/8n1i6av",
};

export default LuziaButton;
