import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';
import Image from 'gatsby-image';
import { Link } from 'gatsby';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
// import Input from 'common/src/components/Input';
import FeatureBlock from 'common/src/components/FeatureBlock';
import ParticlesComponent from '../particles';
import Container from 'common/src/components/UI/Container';
import { Icon } from 'react-icons-kit';
import { ic_arrow_forward } from 'react-icons-kit/md/ic_arrow_forward';
import { BannerSquareShape, BannerCircleShape } from '../app.style';
import {
  // DiscountWrapper,
  // DiscountLabel,
  ButtonWrapper,
  // EmailInputWrapper,
} from './banner.style';

const DomainSection = ({
  SectionWrapper,
  row,
  col,
  title,
  description,
  button,
  image,
  imageArea,
  imageLuzia,
  imageLuzCol,
  btnStyleTwo,
  // discountAmount,
  // discountText,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      appScreenshot: file(relativePath: { eq: "image/app/mobile.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      },
      luzImage: file(relativePath: { eq: "image/app/luziaEmoji.jpg" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Box {...SectionWrapper}>
      <ParticlesComponent />
      <BannerSquareShape />
      <BannerCircleShape />
      <Container>
        <Box {...row}>
          <Box {...col}>
            <Box>
              {/* <DiscountWrapper>
                <DiscountLabel>
                  <Text {...discountAmount} className="discountAmount" />
                  <Text {...discountText} />
                </DiscountLabel>
              </DiscountWrapper> */}
            </Box>
            <Box {...imageLuzCol} {...imageLuzia}>
            <Box {...image}>
              <Image
                fluid={
                  (Data.luzImage !== null) | undefined
                    ? Data.luzImage.childImageSharp.fluid
                    : {}
                }
                alt="LuzIA"
              />
            </Box>
          </Box>
            <FeatureBlock
              title={<Heading {...title} />}
              description={<Text {...description} />}
            />
            {/* <EmailInputWrapper>
              <Input
                inputType="email"
                placeholder="Enter Email Address"
                iconPosition="left"
                aria-label="email"
              />
            </EmailInputWrapper> */}
            <ButtonWrapper>
              <Link to="#download">
                <Button title="Descarga la App!" {...button} />
              </Link>
              <a href="https://app.luzia.chat">
                <Button
                  {...button}
                  {...btnStyleTwo}
                  title="Pruébalo Ahora"
                  icon={<Icon icon={ic_arrow_forward} />}
                  className="withoutBg"
                />
              </a>
            </ButtonWrapper>
          </Box>
          <Box {...col} {...imageArea}>
            <Box {...image}>
              <Image
                fluid={
                  (Data.appScreenshot !== null) | undefined
                    ? Data.appScreenshot.childImageSharp.fluid
                    : {}
                }
                alt="LuzIA App"
              />
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

DomainSection.propTypes = {
  SectionWrapper: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
  btnStyleTwo: PropTypes.object,
  discountAmount: PropTypes.object,
  discountText: PropTypes.object,
  textArea: PropTypes.object,
};

DomainSection.defaultProps = {
  SectionWrapper: {
    as: 'section',
    pt: '80px',
    pb: '80px',
    overflow: 'hidden',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    alignItems: 'center',
  },
  imageAreaRow: {
    flexDirection: 'row-reverse',
  },
  imageLuzCol: {    
    mb: '25px',
    mt: '-30px',
  },
  col: {
    pr: '15px',
    pl: '15px',
    width: ['100%', '100%', '50%', '44%', '44%'],
    mt: '-80px',
  },
  imageArea: {
    width: ['0%', '0%', '43%', '35%', '40%'],
    ml: 'auto',
  },
  imageLuzia: {
    width: ['50%', '50%', '0%', '0%', '0%'],
    marginLeft: '25%',
    
  },
  title: {
    content: 'Te acompaño a convertir los momentos de crisis en experiencias de prosperidad con herramientas de inteligencia artificial',
    fontSize: ['26px', '30px', '30px', '34px', '38px'],
    fontWeight: '400',
    color: '#0f2137',
    letterSpacing: '-0.01px',
    textAlign: 'center',
    mb: '50px',
    mt: ['0px', '0px', '-60px','-160px','-320px']
  },
  description: {
    content:
      '',
    fontSize: '16px',
    color: '#343d48',
    lineHeight: '33px',
    mb: '10px',
  },
  button: {
    type: 'button',
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
    color: '#fff',
    borderRadius: '4px',
    pl: ['22px','22px','22px','12px','22px'],
    pr: ['22px','22px','22px','12px','22px'],
    colors: 'primaryWithBg',
  },
  image: {
    ml: 'auto',
    mt: '70px',
  },
  btnStyleTwo: {
    minWidth: '156px',
    ml: '15px',
    bg: '#fff',
    color: '#B200FF',
  },
  textArea: {
    width: ['100%', '100%', '50%', '55%', '55%'],
  },
  discountAmount: {
    content: 'update',
    fontSize: '14px',
    fontWeight: '600',
    color: '#fff',
    mb: 0,
    as: 'span',
    mr: '0.4em',
    bg: 'rgb(26, 115, 232)',
  },
  discountText: {
    content: 'Version 2.5.0 has just released .',
    fontSize: '13px',
    fontWeight: '400',
    color: '#0f2137',
    mb: 0,
    as: 'span',
    ml: '10px',
  },
};

export default DomainSection;
