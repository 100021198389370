import React from 'react';
import PropTypes from 'prop-types';
import ImageGallery from 'react-image-gallery';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import 'react-image-gallery/styles/css/image-gallery.css';
import Container from 'common/src/components/UI/Container';
import FeatureSliderWrapper from './featureSlider.style';
import Image1 from 'common/src/assets/image/app/1-herramientas.jpg';
import Image2 from 'common/src/assets/image/app/2-cursos.jpg';
import Image3 from 'common/src/assets/image/app/3-aceptacion.jpg';
import Image4 from 'common/src/assets/image/app/4-equilibrio.jpg';
import Image5 from 'common/src/assets/image/app/5-miedos.jpg';
import Image6 from 'common/src/assets/image/app/6-meditaciones.jpg';
import Image7 from 'common/src/assets/image/app/7-creencias.jpg';
import Image8 from 'common/src/assets/image/app/8-videos.jpg';
import Thumb1 from 'common/src/assets/image/app/1-herramientas.svg';
import Thumb2 from 'common/src/assets/image/app/2-cursos.svg';
import Thumb3 from 'common/src/assets/image/app/3-aceptacion.svg';
import Thumb4 from 'common/src/assets/image/app/4-equilibrio.svg';
import Thumb5 from 'common/src/assets/image/app/5-miedos.svg';
import Thumb6 from 'common/src/assets/image/app/6-meditaciones.svg';
import Thumb7 from 'common/src/assets/image/app/7-creencias.svg';
import Thumb8 from 'common/src/assets/image/app/8-videos.svg';

// import DomainSection from '../container/Hosting/Domain';
const images = [
  {
    original: `${Image1}`,
    thumbnail: `${Thumb1}`,
    thumbnailLabel: 'Herramientas para Sanar',
    originalAlt: 'herramientas',
    thumbnailAlt: 'herramientas',
  },
  {
    original: `${Image2}`,
    thumbnail: `${Thumb2}`,
    thumbnailLabel: 'Cursos de Gestión Emocional',
    originalAlt: 'cursos',
    thumbnailAlt: 'cursos',
  },
  {
    original: `${Image3}`,
    thumbnail: `${Thumb3}`,
    thumbnailLabel: 'Aceptación y Gestión de Crisis',
    originalAlt: 'aceptación',
    thumbnailAlt: 'aceptación',
  },
  {
    original: `${Image4}`,
    thumbnail: `${Thumb4}`,
    thumbnailLabel: 'Recupera tu Equilibrio',
    originalAlt: 'equilibrio',
    thumbnailAlt: 'equilibrio',
  },
  {
    original: `${Image5}`,
    thumbnail: `${Thumb5}`,
    thumbnailLabel: 'Aprende a Sanar tus miedos',
    originalAlt: 'miedos',
    thumbnailAlt: 'miedos',
  },
  {
    original: `${Image6}`,
    thumbnail: `${Thumb6}`,
    thumbnailLabel: 'Meditaciones para tu Paz interior',
    originalAlt: 'meditaciones',
    thumbnailAlt: 'meditaciones',
  },
  {
    original: `${Image7}`,
    thumbnail: `${Thumb7}`,
    thumbnailLabel: 'Inducciones',
    originalAlt: 'inducciones',
    thumbnailAlt: 'inducciones',
  },
  {
    original: `${Image8}`,
    thumbnail: `${Thumb8}`,
    thumbnailLabel: 'Videos, Audios y Ejercicios',
    originalAlt: 'videos',
    thumbnailAlt: 'audios',
  },
];

const FeatureSlider = ({ sectionSubTitle, sectionTitle, sectionHeader }) => {
  return (
    <FeatureSliderWrapper>
      <div className="FeatureSliderInner">
        <span> </span>
        <span> </span>
        <span> </span>
      </div>
      <Container>
        <Box {...sectionHeader}>
          <Text {...sectionSubTitle} />
          <Heading {...sectionTitle} />
        </Box>
        <Box className="FeatureSlider">
          <ImageGallery
            items={images}
            className="Slider-img"
            showPlayButton={false}
            showFullscreenButton={false}
            showNav={false}
            showBullets={true}
            autoPlay={true}
          />
        </Box>
      </Container>
    </FeatureSliderWrapper>
  );
};

// FeatureSlider style props
FeatureSlider.propTypes = {
  sectionSubTitle: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionHeader: PropTypes.object,
};

// FeatureSlider default style
FeatureSlider.defaultProps = {
  sectionHeader: {},
  sectionSubTitle: {
    content: 'QUE ENCONTRARÁS EN LUZIA',
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.13em',
    fontWeight: '700',
    color: '#B200FF',
    mb: '10px',
  },
  // section title default style
  sectionTitle: {
    content: 'Gran cantidad de Contenido para tu Prosperidad',
    textAlign: 'center',
    fontSize: ['20px', '24px', '24px', '24px', '30px'],
    fontWeight: '400',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
  },
};

export default FeatureSlider;
